<template>
  <div>
    <div v-if="filters">
      filtrar: {{ filters.map((it) => it.nome + " | ") }}
    </div>
    <b-table
      striped
      hover
      :items="lista"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
      small
      style="font-size: 10px"
      @row-clicked="openMovimento"
    >
  
      <template #cell(data)="row">
        {{ row.value | moment("DD/MM/YYYY") }}
      </template>
      <template #cell(dt_consolidado)="row">
        {{ row.value | moment("DD/MM/YYYY HH:mm") }}
      </template>
      <template #cell(dt_vencimento)="row">
        {{ row.value | moment("DD/MM/YYYY") }}
      </template>
      <template #cell(dt_competencia)="row">
        {{ row.value | moment("DD/MM/YYYY") }}
      </template>
      <template #cell(dt_pagamento)="row">
        {{ row.value | moment("DD/MM/YYYY") }}
      </template>
      <template #cell(valor)="row">
        {{ row.value | currency }}
      </template>
      <template #cell(valor_pago)="row">
        {{ row.value | currency }}
      </template>
      <template #cell(valor_total)="row">
        {{ row.value | currency }}
      </template>
      <template #cell(valor_total_pago)="row">
        {{ row.value | currency }}
      </template>
      <template #cell(observacao)="row">
        <div
          :class="{
            'text-success': row.item.tipo == 1,
            'text-danger': row.item.tipo == -1,
          }"
        >
          <b-icon-arrow-down
            v-if="row.item.tipo == -1"
            class="text-danger"
          ></b-icon-arrow-down>
          <b-icon-arrow-up
            v-if="row.item.tipo == 1"
            class="text-success"
          ></b-icon-arrow-up>
          {{ row.item.historico }}
        </div>
        {{ row.value }}
      </template>
      <template #cell(acao)="row">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="detalhes(row.item)"
          >Detalhes</b-button
        >
      </template>
    </b-table>
    <b-row>
      <b-col class="my-1 d-flex">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
        <v-select
          key="perpage"
          class="ml-4"
          :clearable="false"
          v-model="perPage"
          :options="[10, 20, 30, 40, 50]"
        >
          <template #selection="{ item }">{{ item }}</template>
        </v-select>
      </b-col>
    </b-row>
    <b-modal
      :id="idModal"
      hide-footer
      title="Adicionar/Editar Movimento"
      no-enforce-focus
    >
      <movimento-form @cancelar="closeMovimento" :itemEdit="itemEdit" />
    </b-modal>
  </div>
</template>

<script>
import MovimentoForm from "../Financeiro/Bancario/MovimentoForm.vue";
export default {
  components: { MovimentoForm },
  props: {
    lista: Array,
    filters: Array,
  },
  mounted() {
    this.totalRows = this.lista.length;
  },
  data() {
    return {
      idModal: "modal-add-movimento" + Math.random(),
      fields: [
        { key: "sel", label: "" },
        { key: "data", label: "Dt. Lançamento", sortable: true },
        { key: "dt_consolidado", label: "Dt. Consoliação", sortable: true },
        { key: "dt_competencia", label: "Dt. Competencia", sortable: true },
        { key: "carteira", label: "Carteira", sortable: true },
        { key: "fornecedor", label: "Fornecedor", sortable: true },
        { key: "planoContas", label: "PL. Contas", sortable: true },
        { key: "centro_custo", label: "Cent. Custo", sortable: true },
        { key: "observacao", label: "Obs.", sortable: true },
        { key: "valor", label: "Valor", sortable: true },
        // { key: "acao", label: "Ação" },
      ],
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      totalRows: 0,
      itemEdit: null,
    };
  },
  watch: {
    lista: {
      handler() {
        this.totalRows = this.lista.length;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    closeMovimento() {
      this.$bvModal.hide(this.idModal);
      this.$emit("reload");
    },
    openMovimento(item) {
      console.log(item);
      this.itemEdit = item;
      this.$bvModal.show(this.idModal);
    },
    onFiltered(filteredItems) {
      console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    detalhes(item) {
      this.$emit("detalhes", item);
    },
  },
};
</script>

<style lang="scss" scoped></style>
